import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import {
  getContentProjects,
  getContents,
} from "../../../services/contentServices";
import DueDateFilter from "../../includes/Filter/DueDateFilter";
import HumanResource from "../../includes/Filter/HumanResource";
import ProjectFilter from "../../includes/Filter/ProjectFilter";
import Pagination from "../../includes/Pagination";
import GettingDataSpinner from "../../includes/Spinners/GettingDataSpinner";
import AdvanceSearch from "./AdvanceSearch";
import ContentManagerContentsTable from "./ContentManagerContentsTable";
import ManagerContentsTable from "./ManagerContentsTable";

const ContentManagerContents = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [refresh, setRefresh] = useState(false);

  const [contents, setContents] = useState([]);
  const [results, setResults] = useState(0);

  const user = useSelector((state) => state.user);

  const [searchName, setSName] = useState("");
  const [searchStatus, setSStatus] = useState("");

  // * search variables
  const [websiteList, setWebsiteList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [statusStage, setStatusStage] = useState("");
  const [statusText, setStatusText] = useState("");

  const [DMQuery, setDMQuery] = useState("");

  const [writer, setWriter] = useState("");
  const [writerFlag, setWriterFlag] = useState(true);

  const [editor, setEditor] = useState("");
  const [editorFlag, setEditorFlag] = useState("");

  const [contentManager, setContentManager] = useState("");
  const [contentManagerFlag, setContentManagerFlag] = useState(true);

  const [designManager, setDesignManager] = useState("");
  const [designManagerFlag, setDesignManagerFlag] = useState(true);

  const [designer, setDesigner] = useState("");
  const [designerFlag, setDesignerFlag] = useState(true);

  const [projectFlag, setProjectFlag] = useState(false);
  const [dueDateFlag, setDueDateFlag] = useState(false);
  const [HRFlag, setHRFlag] = useState(false);
  // *----------------------------------------
  let searchsQuery = `page=${page}&limit=${limit}`;
  let searchObject = {};

  useEffect(() => {
    SearchHandler();
  }, [page, user, limit, refresh]);

  const fetchContent = async (query, filter) => {
    try {
      const token = user.token;
      if (!token) return;

      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);

      const { data } = await getContents(token, query, filter);
      console.log(data.contents);
      setResults(data.results);
      setContents(data.contents);
      setWebsiteList(data.weblist);

      setDialog(false);
    } catch (error) {
      setDialog(false);

      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const SearchHandler = () => {
    searchsQuery = `page=${page>0?page:1}&limit=${limit>10?limit:10}`
    
    if (searchName != "") searchObject["subject"] = searchName;
    if (projectName != "") searchObject["website"] = projectName;
    if (statusText != "") searchObject["status.text"] = statusText;
    if (statusStage != "") searchObject["status.stage"] = statusStage;
    if (fromDate != "" && toDate != "")
      searchObject["dueDate"] = { gte: fromDate, lte: toDate };

    if (editorFlag !== "") searchObject["editor"] = editorFlag;
    if (editor != "") searchObject["editor"] = editor;

    if (!writerFlag) searchObject["writer"] = writerFlag;
    else if (writer != "") searchObject["writer"] = writer;

    fetchContent(searchsQuery, searchObject);
  };


  const advanceSearchHandler = () => {
    setDialogClasses(
      "bg-white rounded-lg p-4 items-center justify-center gap-4 container h-[80%] overflow-y-scroll"
    );
    setDialog(<AdvanceSearch />);
  };
  
  return (
    <div className="h-full overflow-y-scroll p-8 ">
      {/* Filters */}

      <div className="flex flex-col lg:flex-row rounded-lg px-4 py-2 bg-white justify-between items-center mb-4  gap-4">
        <div className="flex w-full xl:items-center gap-2 flex-col  xl:flex-row">
          <div className="flex flex-col md:flex-row md:items-center gap-2 ">
            <label htmlFor="title" className="w-[120px] xl:w-auto">
              Title:
            </label>
            <input
              name="title"
              type="text"
              className="py-4 px-4 bg-gray-100 rounded-xl  lg:min-w-[300px] w-full lg:w-auto outline-none"
              placeholder="Title..."
            />
          </div>

          <div className="flex flex-col md:flex-row md:items-center gap-2 ">
            <label htmlFor="title" className="w-[120px] xl:w-auto">
              Content Status:
            </label>

            <select
              type="text"
              className="py-4 px-4 bg-gray-100 rounded-xl  lg:min-w-[300px] w-full lg:w-auto  outline-none"
              placeholder="Title..."
            >
              <option value="" selected>
                All Contents
              </option>
            </select>
          </div>
        </div>
        <div className="xs:flex-row flex flex-col gap-2 xs:items-center  ">
          <button onClick={()=>setRefresh(!refresh)}
            className="rounded-full bg-blue-50 justify-between text-blue-600 flex items-cneter gap-1 px-4 py-2 w-full"
            title="Refresh the Contentes"
          >
            <p className="text-blue-600 xs:hidden">Referesh</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>
          <button
            className="rounded-full bg-purple-50 text-purple-800 flex justify-between items-cneter gap-1 px-4 py-2"
            title="Advance Search"
            onClick={advanceSearchHandler}
          >
             <p className="text-purple-800 xs:hidden">Filters</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
              />
            </svg>
          </button>
          <button className="rounded-full bg-green-50 text-green-700 justify-between flex items-cneter gap-1 px-4 py-2">
            <p>Search</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Filters End */}
      <ContentManagerContentsTable
        setDialog={setDialog}
        setDialogClasses={setDialogClasses}
        contents={contents}
        setContents={setContents}
      />
      <Pagination
        results={results}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
      />
    </div>
  );
};

export default ContentManagerContents;

import { useEffect } from "react";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import { serverLogin } from "../../services/authServices";
import Spinner from "../includes/Spinner";

const Login = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if(redirect)window.location.replace("/")
  }, [redirect]);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email === "" || password === "") {
      setLoading(false);
      return toast.info("Please Enter the Email and the Password");
    }

    if (loading) return;

    try {
      const { data } = await serverLogin(email, password);
      
      store.dispatch(
        setUser({
          name: data.user.name ? data.user.name : "Not Modified",
          token: data.token,
          email: data.email,
          website: data.website,
          photo: data.user.photo,
          role: data.user.role,
          company: data.user.company,
          _id: data.user._id,
        })
      );
      
      cookies.set("auth", data.token, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
      toast.success("Welcome");
      setRedirect(true);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message?err.response.data.message:err.message);
    }

    setLoading(false);
  };
  return (
    <>
     
      <div
        
        className="flex flex-col  h-[237px] gap-4 relative"
      >
       
        <div className="flex flex-col justify-between items-start gap-[4px]">
          <label htmlFor="Email" className="w-[max-content] text-gray-600">

          Email or Username:
          </label>
          <input
            name="Email"
            onChange={(e) => onChangeHandler(e, setEmail)}
            type="text"
            readOnly={loading}
            placeholder="info@contnetplanet.agency"
            className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm "
          />
        </div>
        <div className="flex flex-col justify-between items-start gap-[4px]">
          <label htmlFor="password" className="w-[max-content] text-gray-600">
           Password:
          </label>
          <input
            onChange={(e) => onChangeHandler(e, setPassword)}
            name="password"
            type="password"
            readOnly={loading}
            placeholder="***********"
            className="bg-[#fff] border-2 outline-none  p-2  rounded-full text-primary backdrop-blur-sm w-full"
          />
        </div>
        <div className="flex gap-2 items-center flex-wrap">
        <button
          type="submit"
          readOnly={loading}
          className="px-16 py-2 w-full md:w-auto bg-purple-800 text-white text-center rounded-full  flex items-center justify-center gap-4"
          onClick={handleSubmit}
        >
          {loading ? <Spinner /> : ""}
          <span>Login</span>
        </button>
        <p className="text-[14px]">Forgot Your Password? <Link className="text-purple-800" to="/auth/forget-password">Reset Now</Link></p>
        </div>
      </div>
    </>
  );
};

export default Login;




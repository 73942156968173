import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";

import ContentStatusConvertor from "../../../utils/ContentStatusConvertor";
import AccountManagerEditBox from "./AccountManagerEditBox";
import DetailBox from "./DetailBox";

const AccountManagerTable = ({ setContents, contents = [{}, {}] }) => {
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;
  const contentStatusConverter = new ContentStatusConvertor();
  const [allCheckBox, setAllCheckBox] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  const { setDialog, setDialogClasses } = useContext(ViewContext);
  useEffect(() => {}, []);
  const checkBoxHandler = (id) => {
    const index = checkedList.findIndex((item) => item.id == id);
    if (index === -1) {
      setCheckedList([...checkedList, { id }]);
    } else {
      const updatedItems = checkedList.filter((item) => item.id !== id);
      setCheckedList(updatedItems);
    }

    const contentsCopy = contents.map((item) =>
      item._id === id ? { ...item, checked: !item.checked } : item
    );
    setContents(contentsCopy);
  };

  const DeselectAll = () => {
    setCheckedList([]);
    const contentsCopy = contents.map((item) => {
      return { ...item, checked: false };
    });
    setContents(contentsCopy);
  };

  const selectAll = () => {
    const updatedItems = [];
    contents.map((item) => updatedItems.push({ id: item._id }));
    setCheckedList(updatedItems);

    const contentsCopy = contents.map((item) => {
      return { ...item, checked: true };
    });
    setContents(contentsCopy);
  };
  const isAllSelected = () => contents.length == checkedList.length;

  const DateConvertor = (date) => {
    if (!date) return "";

    const match = date.match(regex);

    if (match) return match[1].replace("T", "\n");
  };
  const editHandler = (id, el) => {
    setDialogClasses(
      "bg-white rounded-lg p-4 items-center justify-center gap-4 container h-[80%] overflow-y-scroll"
    );
    setDialog(<AccountManagerEditBox ids={[{ _id: id }]} content={el} />);
  };
  const contentRateCaculator = (el) => {
    let sum = 0;
    if (el.contentGrammarRate) sum = sum + el.contentGrammarRate;
    if (el.contentQualityRate) sum = sum + el.contentQualityRate;
    if (el.contentSeoRate) sum = sum + el.contentSeoRate;
    if (el.contentReadabilityRate) sum = sum + el.contentReadabilityRate;
    if (el.contentVisualRate) sum = sum + el.contentVisualRate;
    return sum;
  };

  const detailHandler = (id, el) => {
    setDialogClasses(
      "bg-white rounded-lg p-4 items-center justify-center gap-4 container lg:w-[800px] h-[80%] overflow-y-scroll "
    );
    setDialog(<DetailBox ids={[{ _id: id }]} content={el} />);
  };

  return (
    <div className=" overflow-hidden bg-white p-4 rounded-lg ">
      <div className="flex flex-col items-start overflow-x-scroll divide-y-8">
        <div className="w-[max-content] divide-x-2 flex">
          <div className="w-[400px] bg-[#134f5c] text-white text-center  ">
            <p className="py-1 px-2 text-lg">Main Keyword/Title</p>
          </div>
          {/* content process */}
          <div className="w-[500px] bg-[#660000]  text-white flex flex-col text-center divide-y-2  ">
            <p className="py-1 px-2 text-lg ">Content Process</p>
            <div className="flex divide-x-2 justify-between">
              <p className="py-1 px-2 w-full text-center">Content Status</p>
              <p className="py-1 px-2 w-full text-center">SEO Status</p>
              <p className="py-1 px-2 w-full text-center">Image Status</p>
            </div>
          </div>
          {/* Reviewer */}
          <div className="w-[800px] bg-[#0b5394] text-white flex flex-col text-center divide-y-2">
            <p className="py-1 px-2 text-lg">Reviewer</p>
            <div className="flex divide-x-2 justify-between">
              <p className="py-1 px-2 w-full text-center">Name</p>
              <p className="py-1 px-2 w-full text-center">Assign Date</p>
              <p className="py-1 px-2 w-full text-center">Delivery Date</p>
              <p className="py-1 px-2 w-full text-center">DueDate Date</p>
              <p className="py-1 px-2 w-full text-center">Quality Rate</p>
            </div>
          </div>
          {/* writer */}
          <div className="w-[600px] bg-[#351c75] text-white flex flex-col text-center divide-y-2">
            <p className="py-1 px-2 text-lg">Writer</p>
            <div className="flex divide-x-2 justify-between">
              <p className="py-1 px-2 w-full text-center">Name</p>
              <p className="py-1 px-2 w-full text-center">Assign Date</p>
              <p className="py-1 px-2 w-full text-center">Delivery Date</p>
              <p className="py-1 px-2 w-full text-center">DueDate Date</p>
            </div>
          </div>
          {/* Type */}
          <div className="w-[300px] bg-[#741b47] text-white flex flex-col text-center divide-y-2">
            <p className="py-1 px-2 text-lg">Type</p>
            <div className="flex divide-x-2 justify-between">
              <p className="py-1 px-2 w-full text-center">Action</p>
              <p className="py-1 px-2 w-full text-center">Type</p>
            </div>
          </div>
          {/* keyword Count */}
          <div className="w-[500px] bg-[#0b5394] text-white flex flex-col text-center divide-y-2">
            <p className="py-1 px-2 text-lg">Keyword Count</p>
            <div className="flex divide-x-2 justify-between">
              <p className="py-1 px-2 w-full text-center">
                Estimate Words Count
              </p>
              <p className="py-1 px-2 w-full text-center">Final Words Count</p>
            </div>
          </div>
          {/* Peyment */}
          <div className="w-[400px] bg-[#134f5c] text-white text-center  ">
            <p className="py-1 px-2 text-lg">Payment Status</p>
          </div>
          {/* Actions */}
          <div className="w-[500px] bg-[#0b5394] text-white flex flex-col text-center divide-y-2">
            <p className="py-1 px-2 text-lg">Actions</p>
          </div>
        </div>

        {/* loop */}
        {contents.map((el) => (
          <div className="w-[max-content] items-center flex  divide-x-2">
            <div className="w-[400px]  text-center ">
              <p className="py-1 px-2 text-lg">{el.subject}</p>
            </div>

            {/* content process */}
            <div className="w-[500px]  flex  text-center divide-x-2 justify-between px-2 gap-1">
              <p className="py-1 px-2 w-full text-center bg-green-700 text-white rounded-full text-sm">
                {contentStatusConverter.contentStatusConverter(el.status)}
              </p>
              <p className="py-1 px-2 w-full text-center bg-blue-800 text-white rounded-full text-sm">
                {contentStatusConverter.SEOStatusConverter({
                  stage: "",
                  text: "",
                })}
              </p>
              <p className="py-1 px-2 w-full text-center bg-orange-800 text-white rounded-full text-sm">
                {contentStatusConverter.mediaStatusConverter(el.imageStatus)}
              </p>
            </div>
            {/* Reviewer */}
            <div class="w-[800px]  flex flex-col text-center divide-y-2">
              <div class="flex divide-x-2 justify-between items-center">
                <p class=" px-2 w-full text-center">
                  {el.editor[0] ? el.editor[0].name : "Not Assiged"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.assignDate && el.assignDate.editor
                    ? DateConvertor(el.assignDate.editor)
                    : "Not Modified"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.contentDeliveryDate && el.contentDeliveryDate.editor
                    ? DateConvertor(el.contentDeliveryDate.editor)
                    : "Not Modified"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.contentDueDate && el.contentDueDate.editor
                    ? DateConvertor(el.contentDueDate.editor)
                    : "Not Modified"}
                </p>
                <div className="px-2 w-full text-center">
                  <p class="py-1 px-2 bg-orange-800 text-white rounded-full text-sm">
                    {contentRateCaculator(el)}
                  </p>
                </div>
              </div>
            </div>
            {/* writer */}
            <div class="w-[600px]  flex flex-col text-center divide-y-2">
              <div class="flex divide-x-2 justify-between">
                <p class="py-1 px-2 w-full text-center">
                  {el.writer[0] ? el.writer[0].name : "Not Assiged"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.assignDate && el.assignDate.writer
                    ? DateConvertor(el.assignDate.writer)
                    : "Not Modified"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.contentDeliveryDate && el.contentDeliveryDate.writer
                    ? DateConvertor(el.contentDeliveryDate.writer)
                    : "Not Modified"}
                </p>
                <p class="py-1 px-2 w-full text-center">
                  {el.contentDueDate && el.contentDueDate.writer
                    ? DateConvertor(el.contentDueDate.writer)
                    : "Not Modified"}
                </p>
              </div>
            </div>
            {/* Type */}
            <div className="w-[300px] flex flex-col text-center divide-y-2">
              <div className="flex divide-x-2 justify-between">
                <div className="px-2 w-full text-center">
                  <p class="py-1 px-2 bg-yellow-800 text-white rounded-full text-sm">
                    {el.contentType.action
                      ? el.contentType.action
                      : "Not Modified"}
                  </p>
                </div>
                <div className="px-2 w-full text-center">
                  <p class="py-1 px-2 bg-orange-800 text-white rounded-full text-sm">
                    {el.contentType.type ? el.contentType.type : "Not Modified"}
                  </p>
                </div>
              </div>
            </div>
            {/* keyword Count */}
            <div className="w-[500px]  flex flex-col text-center divide-y-2">
              <div className="flex divide-x-2 justify-between">
                <p className="py-1 px-2 w-full text-center">{el.WordCount}</p>
                <p className="py-1 px-2 w-full text-center">
                  {" "}
                  {el.finalWordCount ? el.finalWordCount : 0}
                </p>
              </div>
            </div>
            {/* Peyment */}
            <div className="w-[max-content] items-center flex  divide-x-2">
              <div className="w-[400px]  text-center px-2">
                <p className="py-1 px-2 bg-green-800 text-white rounded-full text-sm">
                  {el.paymentStatus}
                </p>
              </div>
            </div>
            {/* Actions */}
            <div className="w-[500px]  flex flex-col text-center divide-y-2">
              <div className="flex justify-center gap-2 items-center">
                <Link
                  to={`https://drive.google.com/drive/u/0/folders/${el.contentDrive}`}
                  target={"_blank"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 my-1 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </Link>

                <svg
                  onClick={() => editHandler(el._id, el)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  my-1 mx-2 hover:cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
                <button onClick={(e) => detailHandler(el._id,el)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 my-1 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                    />
                  </svg>
                </button>
                <Link to={`/contents/account-manager/${el._id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 my-1 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountManagerTable;

import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ViewContext } from "../../context/ViewContext";
import {
  UpdateMe,
  UpdatePassword,
  UploadPhoto,
} from "../../services/userServices";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const Profile = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const user = useSelector((state) => state.user);
  const cookies = new Cookies();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [company, setCompany] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [file, setFile] = useState({});

  useEffect(() => {
    setName(user.name);
    if (user.website) setWebsite(user.website);
    if (user.email) setEmail(user.email);
    if (user.company) setCompany(user.company);
console.log(user)

  }, [user]);

  const updatePassword = async (e) => {
    e.preventDefault();
    try {
      if (
        newPassword === "" ||
        confirmPassword === "" ||
        currentPassword === ""
      ) {
        toast.warning("Please fill all the fields");
        return;
      }
      if (newPassword != confirmPassword) {
        toast.warning("The new Password and it's confirm are not match!");
        return;
      }
      const formdata = new FormData();
      formdata.append("password", newPassword);
      formdata.append("passwordConfirm", confirmPassword);
      formdata.append("currentPassword", currentPassword);
      const token = user.token;
      const { data } = await UpdatePassword(token, formdata);

      store.dispatch(
        setUser({
          name: data.user.name || data.user.username,
          token: data.token,
          photo: data.user.photo,
          role: data.user.role,
          id: data.user._id,
        })
      );

      cookies.set("auth", data.token, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });

      setNewPassword("");
      setConfirmPassword("");
      setCurrentPassword("");
      toast.success("پسورد شما به روز شد");
    } catch (error) {
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
    // set redux
  };

  const uploadUserPhoto = async () => {
   
    const formdata = new FormData();
    formdata.append("file", file);
    const token = user.token;
    try {
      
      const { data } = await UploadPhoto(token, formdata);
      toast.success("عکس پروفایل آپلود شد");
    } catch (error) {
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };
  const onChangeHandler = (e, state) => {
    
    state(e.target.value);
  };
  const updateInfo = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    try {

      formdata.append("name", name);
      formdata.append("website", website);
      formdata.append("company", company);

      const token = user.token;
      const { data } = await UpdateMe(token, formdata);


      toast.success("مشخصات شما به روز رسانی شد");
    } catch (error) {
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  const fileChangeHandler = async (e) => {
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    const token = user.token;
    try {
      const { data } = await UploadPhoto(token, formdata);
      toast.success("عکس پروفایل آپلود شد");
    } catch (error) {
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }

  };

  return (
    <div className="grid grid-cols-12 gap-4 p-8 overflow-y-scroll h-full">
      <div className="col-span-12 lg:col-span-5 flex flex-col gap-4">
        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex justify-between items-start ">
            <div className="flex flex-col ">
              <p className="text-blue-900 font-bold text-[20px]">
                Personal Information
              </p>
              <p className="text-gray-400 text-[14px]">Your Profile</p>
            </div>

            <p className="text-gray-400 text-[14px] text-left mt-[2px] hidden md:block">
              Joined At 2024/05/10
            </p>
          </div>
          <div className="flex gap-4 items-center flex-wrap md:flex-nowrap">
            <div className="relative">
              <img
                src={`https://api.modirecontent.com/img/users/${user.photo}`}
                alt=""
                className="w-[100px] rounded-xl"
              />
              <button className="absolute bottom-2 right-1 transition-all duration-[0.3s] cursor-pointer p-[2px] rounded-full bg-white text-blue-900 hover:text-white hover:bg-purple-800" onClick={()=>{
                let file = document.getElementById("PhotoFile");
                file.click()
              }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
              <input type="file"  onChange={(e)=>fileChangeHandler(e)} id="PhotoFile" className="hidden"/>

            </div>
            <div className="flex flex-col">
              <p className="font-bold text-purple-800 text-[20px]">{name}</p>
              <p className="text-gray-400 text-[14px]">
                {company || "No Company"}
              </p>
            </div>
          </div>
        </div>

        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex flex-col ">
            <p className="text-blue-900 font-bold text-[20px]">
              Password Setting:
            </p>
            <p className="text-gray-400 text-[14px]">Your Password</p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="current password"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Current Password:
              </label>
              <input
                name="current password"
                type="text"
                onChange={(e) => onChangeHandler(e, setCurrentPassword)}
                placeholder="Enter your Current Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="new password"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                New Password:
              </label>
              <input
                name="new password"
                type="password"
                onChange={(e) => onChangeHandler(e, setNewPassword)}
                placeholder="Enter your new Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="confirm password"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Confirm Password:
              </label>
              <input
                name="confirm password"
                type="password"
                onChange={(e) => onChangeHandler(e, setConfirmPassword)}
                placeholder="Confirm your Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex gap-2 items-center flex-wrap justify-end">
              <button
                type="submit"
                onClick={(e) => updatePassword(e)}
                className="px-10 py-2 w-full md:w-auto bg-purple-100 bg-opacity-50 text-purple-800 font-medium text-center rounded-full hover:bg-transparent border-2 border-transparent hover:border-purple-800 border-purple-700 flex items-center justify-center gap-4 transition-all duration-[0.3s]"
              >
                <span>Update Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-7 ">
        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex flex-col ">
            <p className="text-blue-900 font-bold text-[20px]">Information:</p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Your Username:
              </label>
              <input
                value={user.username}
                readOnly
                name="Username"
                type="text"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>

            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="email"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Email:
              </label>
              <input
                readOnly
                name="email"
                type="text"
                value={email}
                placeholder="Confirm your Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <hr className="w-full opacity-55 " />
            <div className="flex flex-col ">
              <p className="text-blue-900 font-bold text-[20px]">
                Edit Information:
              </p>
              <p className="text-gray-400 text-[14px]">
                Edit your Personal Information
              </p>
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="name"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Your Fullname:
              </label>
              <input
                name="name"
                type="text"
                onChange={(e) => onChangeHandler(e, setName)}
                value={name}
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Company’s name:
              </label>
              <input
                onChange={(e) => onChangeHandler(e, setCompany)}
                value={company}
                name="Username"
                type="text"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>

            {/* 
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Your Address:
              </label>
              <input
                name="Username"
                type="text"
           
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div> */}
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Your Websote:
              </label>
              <input
                name="website"
                value={website}
                onChange={(e) => onChangeHandler(e, setWebsite)}
                type="text"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex gap-2 items-center flex-wrap justify-end">
              <button
                onClick={updateInfo}
                type="submit"
                className="px-10 py-2 w-full md:w-auto bg-purple-100 bg-opacity-50 text-purple-800 font-medium text-center rounded-full hover:bg-transparent border-2 border-transparent hover:border-purple-800 border-purple-700 flex items-center justify-center gap-4 transition-all duration-[0.3s]"
              >
                <span>Edit Information</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import { useEffect, useState } from "react";
import { useContext } from "react";
import { ViewContext } from "../../../context/ViewContext";

const InformationBoxHandler = ({ content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  const DateConvertor = (date) => {
    if (!date) return "";

    const match = date.match(regex);

    if (match) return match[1].replace("T", "\n");
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
            {content.subject}
          </p>
        </div>
        <button
          onClick={() => setDialog(false)}
          className="p-1 rounded bg-purple-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-purple-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
      <div className="grid grid-cols-12 w-full gap-4">
        <div className=" col-span-12 lg:col-span-6 flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Title:
            </label>
            <input
              readOnly
              value={content.subject}
              type="text"
              className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
            />
          </div>

          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Description:
            </label>
            <textarea
              readOnly
              value={content.description}
              type="Number"
              className="bg-[#EFF1F999] px-2 py-2 outline-none h-[200px] w-full rounded-lg text-[#8B8D97] resize-none"
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Refrences
            </label>
            {content.references && content.references.length > 0 ? (
              <>
                {content.references.map((el) => (
                  <input
                    type="text"
                    className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                    value={el}
                    readOnly
                  />
                ))}
              </>
            ) : (
              <input
                type="text"
                readOnly
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                value={"There is no Reference for this content"}
              />
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b] w-[200px]">
              Subtitles:
            </label>
            <textarea
              type="Number"
              value={content.subtitles}
              className="bg-[#EFF1F999] px-2 py-2 outline-none h-[241px] w-full rounded-lg text-[#8B8D97] resize-none"
            />
          </div>

          <hr className=" text-gray-500 opacity-50 mt-2" />
        </div>
        <div className=" col-span-12 lg:col-span-6 flex gap-4 flex-col  ">
          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-4 ">
            <div className="grid grid-cols-3 gap-4">
              <div className="flex flex-col gap-2 w-full col-span-1">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Primary Keyword:
                </label>
                <input
                  readOnly
                  value={content.PKW}
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-1">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Alternative Keyword:
                </label>
                <input
                  readOnly
                  value={content.AKW}
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-1">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Secondary Keyword:
                </label>
                <input
                  readOnly
                  value={content.SKW}
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                Other Keywords:
              </label>
              <input
                readOnly
                value={content.keywords}
                type="text"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
              />
            </div>

            <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
              <div className="flex gap-2 flex-col sm:flex-row w-full justify-between  rounded-lg">
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                    Word Estimate:
                  </label>
                  <input
                    readOnly
                    value={content.WordCount}
                    type="Number"
                    className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                    Final Word Count:
                  </label>
                  <input
                    readOnly
                    value={content.finalWordCount}
                    type="Number"
                    className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                  />
                </div>
              </div>
            </div>
            <hr className=" text-gray-500 opacity-50 mt-2" />
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                Details:
              </label>
              <div className="col-span-12 overflow-y-scroll h-[200px] flex flex-col gap-4 p-2 border-[#EFF1F999] border-4 rounded-lg">
                {content.details ? (
                  <>
                    {content.details.map((el) => (
                      <div
                        className={`w-full grid grid-cols-12 gap-2 ${
                          el.fieldType == "input" ? "" : "items-start"
                        }`}
                      >
                        <input
                          type="text"
                          className="col-span-3 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                          placeholder="Field Name"
                          readOnly
                          value={el.name}
                        />
                        {el.fieldType === "input" ? (
                          <input
                            type="text"
                            className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                            placeholder="Detail"
                            value={el.detail}
                            readOnly
                          />
                        ) : (
                          <textarea
                            className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                            readOnly
                            value={el.detail}
                          ></textarea>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr className=" text-gray-500 opacity-50 mt-2" />
            <div className="flex gap-2 w-full flex-col sm:flex-row justify-between rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Grammar Rate:
                </label>
                <input
                  readOnly
                  value={content.contentGrammarRate}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Quality Rate:
                </label>
                <input
                  readOnly
                  value={content.contentQualityRate}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
            <div className="flex gap-2 flex-col sm:flex-row w-full justify-between    rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Readability Rate:
                </label>
                <input
                  readOnly
                  value={content.contentReadabilityRate}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Visuals Rate:
                </label>
                <input
                  readOnly
                  value={content.contentVisualRate}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Content SEO Rate:
            </label>
            <input
              readOnly
              value={content.contentSeoRate}
              type="Number"
              className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
            />
          </div>
          <hr className=" text-gray-500 opacity-50 mt-2" />
        </div>
      </div>

      <div className="grid grid-cols-12 w-full gap-4">
        <div className=" col-span-12 lg:col-span-6 flex flex-col gap-4 w-full">
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.dueDate
                  ? DateConvertor(content.dueDate)
                  : "Not Modified"}
              </p>
            </div>
          </div>

          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Writer's DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.contentDueDate && content.contentDueDate.writer
                  ? DateConvertor(content.contentDueDate.writer)
                  : "Not Modified"}
              </p>
            </div>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Editor's DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.contentDueDate && content.contentDueDate.editor
                  ? DateConvertor(content.contentDueDate.editor)
                  : "Not Modified"}
              </p>
            </div>
          </div>
        </div>
        <div className=" col-span-12 lg:col-span-6 flex flex-col gap-4 w-full">
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Customer Delivery Date:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.contentDeliveryDate &&
                content.contentDeliveryDate.Employer
                  ? DateConvertor(content.contentDeliveryDate.Employer)
                  : "Not Modified"}
              </p>
            </div>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Writer's Delivery Date:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.contentDeliveryDate &&
                content.contentDeliveryDate.editor
                  ? DateConvertor(content.contentDeliveryDate.editor)
                  : "Not Modified"}
              </p>
            </div>
          </div>

          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Editor's Delivery Date:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <p className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]">
                {content.contentDeliveryDate &&
                content.contentDeliveryDate.editor
                  ? DateConvertor(content.contentDeliveryDate.editor)
                  : "Not Modified"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 w-full gap-4">
        <div className="flex flex-col sm:flex-row col-span-12 gap-2 w-full mt-2">
          <button className="px-5 py-2 bg-purple-50 text-purple-900 rounded-full">
            {" "}
            Cancel
          </button>
          <button className="px-5 py-2 bg-purple-900 text-white rounded-full">
            {" "}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default InformationBoxHandler;

import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { updateManyV2 as updateMany } from "../../../services/contentServices";
import { v4 as uuidv4 } from "uuid";

const DetailBox = ({ ids, content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [loading, setLoading] = useState(true);

  const [detailBox, setDetailBox] = useState(false);

  const [fieldType, setFieldType] = useState("input");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");

  const [details, setDetails] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    console.log(details);
    if (content.details) setDetails(content.details);
  }, []);
  const onChangeHandler = (e, state) => {
    state(e.target.value);
    console.log(e.target.value);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    const token = user.token;
    if (!token) return;
    try {
      setLoading(true);

      const { data } = await updateMany(token, {
        updateState: "contentManager",
        contentsID: ids,
        update: { details: details },
      });

      setLoading(false);
      toast.success("Content has been updated");
    } catch (error) {
      setDialog(false);

      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const addDetail = () => {
    const newDetail = {
      name: name,
      fieldType: fieldType,
      detail: detail,
      id: uuidv4(),
    };

    setDetail("");
    setName("");

    const detailCopy = details;
    detailCopy.push(newDetail);
    setDetails(detailCopy);
  };

  const deleteDetail = (idToDelete) => {
    setDetails((prevDetails) =>
      prevDetails.filter((detail) => detail.id !== idToDelete)
    );
  };

  return (
    <div className="p-2 flex flex-col justify-between h-full">
      <div className="">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>

            <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
              {content.subject}
            </p>
          </div>
          <button
            onClick={() => setDialog(false)}
            className="p-1 rounded bg-purple-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 text-purple-800"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
        <div className="grid grid-cols-12 w-full gap-4 ">
          <div className="flex gap-4 justify-between col-span-12 items-center">
            <p className="text-lg font-medium">Details</p>
            <hr className="w-full" />
            <button
              className="py-1 px-2 rounded-lg bg-purple-900 text-white flex w-[210px] gap-2"
              onClick={() => {
                setDetailBox(!detailBox);
              }}
            >
              <p>Add a Detail</p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              > <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d={`${detailBox?"M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z":"M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"}`}
                  />
                
             
                
              </svg>
            </button>
          </div>
          <div
            className={`col-span-12 flex flex-col gap-2 overflow-hidden items-start ${
              detailBox ? "h-auto animate-fadeIn" : "h-0 animate-fadeOut"
            }`}
          >
            <div
              className={`col-span-12 grid grid-cols-12 gap-2 transition-all duration-[.3s]   ${
                fieldType === "textarea" ? "items-start" : ""
              } `}
            >
              <input
                type="text"
                className="col-span-2 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none  rounded-lg"
                placeholder="Field Name"
                onChange={(e) => onChangeHandler(e, setName)}
                value={name}
              />
              <select
                onChange={(e) => onChangeHandler(e, setFieldType)}
                type="text"
                className="col-span-2 bg-[#EFF1F999] h-[41px] text-[#8B8D97] p-2 outline-none  rounded-lg"
              >
                <option value="input" selected>
                  input
                </option>
                <option value="textarea">textarea</option>
              </select>
              {fieldType == "input" ? (
                <input
                  onChange={(e) => onChangeHandler(e, setDetail)}
                  type="text"
                  className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                  placeholder="Detail"
                  value={detail}
                />
              ) : (
                <textarea
                  className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                  onChange={(e) => onChangeHandler(e, setDetail)}
                  value={detail}
                ></textarea>
              )}
            </div>
            <button
              className="px-2 py-1 bg-green-500 text-white rounded-lg flex gap-2 "
              onClick={() => addDetail()}
            >
              <p className="font-medium">Add</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
          <hr className="col-span-12" />
          <div className="col-span-12 overflow-y-scroll h-[200px] flex flex-col gap-4 p-2 border-[#b1b1b4ad] border rounded-lg">
            {details.map((el) => (
              <div
                className={`w-full grid grid-cols-12 gap-2 ${
                  el.fieldType == "input" ? "" : "items-start"
                }`}
              >
                <input
                  type="text"
                  className="col-span-3 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                  placeholder="Field Name"
                  readOnly
                  value={el.name}
                />
                {el.fieldType === "input" ? (
                  <input
                    type="text"
                    className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                    placeholder="Detail"
                    value={el.detail}
                    readOnly
                  />
                ) : (
                  <textarea
                    className="col-span-8 bg-[#EFF1F999] text-[#8B8D97] p-2 outline-none rounded-lg"
                    readOnly
                    value={el.detail}
                  ></textarea>
                )}
                <button
                  className="p-1 rounded-lg col-span-1 bg-pink-600 text-white flex items-center justify-center"
                  onClick={() => deleteDetail(el.id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>

        <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
      </div>
      <div className="w-full bottom-2">
        <button
          onClick={updateHandler}
          className=" border border-purple-900 text-purple-900 rounded-lg px-6 py-2 hover:bg-purple-900 hover:text-white transition-all duration-[.3s]"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DetailBox;

import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import {
  getContentV2,
  getMessages,
  sendMessage,
} from "../../../services/contentServices";
import ContentStatusConvertor from "../../../utils/ContentStatusConvertor";

import GettingDataSpinner from "../../includes/Spinners/GettingDataSpinner";
import ContentManagerEditBox from "./ContentManagerEditBox";

import InformationBox from "./InformationBox";

const ContentManagerChat = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [content, setContent] = useState({});
  const contentStatusConverter = new ContentStatusConvertor()
    .contentStatusConverter;
  const mediaStatusConverter = new ContentStatusConvertor()
    .mediaStatusConverter;
  const user = useSelector((state) => state.user);
  const { id } = useParams() || "NullPostId";

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getManagerContent();
    getMessageHandler();
  }, [user]);

  const informationBoxHandler = () => {
    setDialog(<InformationBox content={content} />);
    setDialogClasses(
      "bg-white rounded-lg p-4 items-center justify-center gap-4 container h-[80%] overflow-y-scroll"
    );
  };
  const editHandler = () => {
    setDialogClasses(
      "bg-white rounded-lg p-4 items-center justify-center gap-4 container h-[80%] overflow-y-scroll"
    );
    setDialog(<ContentManagerEditBox ids={[{ _id: id }]} content={content} />);
  };
  const getMessageHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      const { data } = await getMessages(token, { contentID: id });
      setMessages(data.messages);
      console.log(data.messages);
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const sendMessageHandler = async (e) => {
    e.preventDefault();
    try {
      if (message.length < 10) {
        return toast.info("Your message must be at least 10 characters long.");
      }
      const token = user.token;
      if (!token) return;
      const { data } = await sendMessage(token, { contentID: id, message });
      toast.success("Your message has been sent.");
      setMessage("");
      getMessageHandler();
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const getManagerContent = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);
      const { data } = await getContentV2(token, id, "contentManager");
      setContent(data.content);

      setDialog(false);
    } catch (error) {
      setDialog(false);
      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const DateConvertor = (date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();

    return `${hours}:${minutes}`;
  };

  const RoleConvertor = (role) => {
    switch (role) {
      case "customer":
        return "Employer";
        break;
      case "accountManager":
        return "Account Manager";
        break;
      case "contentManager":
        return "Content Manager";
        break;
      case "writer":
        return "Writer";
        break;
      case "editor":
        return "Editor";
        break;

      case "designer":
        return "Designer";
        break;
      case "designManager":
        return "Media Manager";
        break;
      case "Artificial Intelligence":
        return "AI Assistant";
        break;
      case "system":
        return "System";
        break;
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  const messageViewHandler = (message) => {
    if (message.role === "contentManager") {
      return (
        <div className="flex w-full justify-start">
          <div className="flex gap-2 items-end">
            <img
              src="/img/profile-placeholder.jpg"
              alt=""
              className="rounded-full h-10 w-10 p-1 "
            />
            <div className="flex flex-col bg-[#F3E8FF] p-2  w-full ml-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
              <div className="flex gap-2 text-sm text-[#1C1D22] font-light w-full justify-end">
                <p>Sent at</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </div>
              <hr className="opacity-10" />

              <ReactMarkdown className="text-[#1C1D22] ">
                {message.message}
              </ReactMarkdown>

              <hr className="opacity-10" />
              <div className="flex w-full justify-start">
                <p className="text-sm text-[#1C1D22]  font-light">
                  {RoleConvertor(message.role)}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (message.role === "system") {
      return (
        <div className="flex w-full justify-center">
          <div className="flex flex-col bg-[#F4F5FA] p-2  w-full ml-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
            <div className="flex gap-2 text-sm text-[#1C1D22] font-light w-full justify-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                />
              </svg>
              {RoleConvertor(message.role)}
            </div>
            <hr className="opacity-50" />
            <p className="text-center text-[14px] ">
              <ReactMarkdown>{message.message}</ReactMarkdown>
            </p>
            <hr className="opacity-50" />
            <div className="flex w-full justify-center">
              <p className="flex gap-2 text-sm  font-light w-full justify-center ">
                <p>Sent at</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex w-full justify-end">
          <div className="flex gap-2 items-end ">
            <div className="flex flex-col bg-purple-900 p-2 w-full mr-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
              <div className="flex gap-2 text-sm text-white font-light w-full justify-start">
                <p>Sent at</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </div>
              <hr className="opacity-10" />
              <p className="text-[14px]">
                {" "}
                <ReactMarkdown className="text-white">
                  {message.message}
                </ReactMarkdown>
              </p>
              <hr className="opacity-10" />
              <div className="flex w-full justify-end">
                <p className="text-sm text-white font-light">
                  {RoleConvertor(message.role)}
                </p>
              </div>
            </div>
            <img
              src="/img/profile-placeholder.jpg"
              alt=""
              className="rounded-full h-10 w-10 p-1 "
            />
          </div>
        </div>
      );
    }
  };



  return (
    <div className="overflow-y-scroll h-full">
      <div className="p-4 flex flex-col gap-2">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
              {content.subject}
            </p>
            <button
              title="Click to view the content information"
              onClick={informationBoxHandler}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </button>
            <button className="pb-[3px]" onClick={editHandler}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>

            <Link
              to={`https://drive.google.com/drive/u/0/folders/${content.contentDrive}`}
              className="pb-[3px]"
              target={"_blank"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="p-2 col-span-12 lg:col-span-8 xl:col-span-9 h-md:h-[40rem] h-[30rem] order-1 lg:order-2 rounded-2xl bg-white flex flex-col gap-2">
          <div className="h-full flex flex-col gap-4 overflow-hidden overflow-y-scroll pb-10">
            {messages.map((el) => messageViewHandler(el))}
          </div>

          <div className="h-[50px] ">
            <form className="flex p-1 rounded-full border border-gray-300 items-center ">
              <button
                className="p-1 border rounded-full border-[#1C1D22]"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
              <input
                onChange={(e) => onChangeHandler(e, setMessage)}
                className="text-black text-xs font-medium leading-4 focus:outline-none w-full bg-transparent py-1 px-2"
                placeholder="Type here..."
                value={message}
              />
              <button
                className="rounded-full bg-purple-900 flex gap-2 text-white py-1 px-4 items-center"
                type="submit"
                onClick={sendMessageHandler}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Send 01">
                    <path
                      id="icon"
                      d="M9.04071 6.959L6.54227 9.45744M6.89902 10.0724L7.03391 10.3054C8.31034 12.5102 8.94855 13.6125 9.80584 13.5252C10.6631 13.4379 11.0659 12.2295 11.8715 9.81261L13.0272 6.34566C13.7631 4.13794 14.1311 3.03408 13.5484 2.45139C12.9657 1.8687 11.8618 2.23666 9.65409 2.97257L6.18714 4.12822C3.77029 4.93383 2.56187 5.33664 2.47454 6.19392C2.38721 7.0512 3.48957 7.68941 5.69431 8.96584L5.92731 9.10074C6.23326 9.27786 6.38623 9.36643 6.50978 9.48998C6.63333 9.61352 6.72189 9.7665 6.89902 10.0724Z"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    />
                  </g>
                </svg>
                <p>Send</p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagerChat;

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../app/store";
import MobileMenu from "../components/includes/MobileMenu";
import SideBar from "../components/includes/SideBar";
import { ViewContext } from "../context/ViewContext";
import { setUser } from "../features/user/userSlice";
import { getMe } from "../services/authServices";

const Dashboard = () => {
  const { toggle, setToggle } = useContext(ViewContext);
  const products = useSelector((state) => state.card.products);
  const cookies = new Cookies();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    protect();
  }, []);

  const protect = async () => {
    if (window.location.pathname.startsWith("/?logout=true")) return;
    if (!user.token) {
      if (!cookies.get("auth")) {
        window.location.replace("/auth/login");

        setUser({
          name: "",
          token: "",
          photo: "",
          role: "",
          id: "",
          company: "",
          website: "",
          username: "",
          email: "",
        });
        cookies.remove("auth");
      } else {
        try {
          const token = cookies.get("auth");
          const { data } = await getMe(token);
          
          console.log(data);
          store.dispatch(
            setUser({
              name: data.user.name,
              username: data.user.username,
              token: cookies.get("auth"),
              photo: data.user.photo ,
              role: data.user.role,
              id: data.user._id,
              email: data.user.email,
              website: data.user.website,
              company: data.user.company,
            })
          );
        } catch (error) {
          toast.error(error.response.data.message);
          setUser({
            name: "",
            token: "",
            photo: "",
            role: "",
            id: "",
            company: "",
            website: "",
            username: "",
            email: "",
          });
          cookies.remove("auth");
          window.location.replace("/auth");
        }
      }
    } else {
      // const data = await getinitialData(user.token);
      // console.log(data);
      // toast.success("داده ها دریافت شدند.");
    }
  };

  const TitleHandler = () => {
    if (window.location.pathname.startsWith("/contents")) return "My Contents";
    if (window.location.pathname.startsWith("/dashboard")) return "Dashboard";
    if (window.location.pathname.startsWith("/projects")) return "My Projects";
    if (window.location.pathname.startsWith("/store")) return "Store";
    if (window.location.pathname.startsWith("/invoice")) return "Invoice";
    if (window.location.pathname.startsWith("/profile")) return "My Profile";
    if (window.location.pathname.startsWith("/card")) return "Card";
  };

  return (
    <>
      <SideBar />
      <MobileMenu />
      <div
        className={` rounded-lg transition-all mb-20	ease-in-out duration-[.3s] w-full ${
          toggle ? "md:w-[calc(100%-250px)]" : "md:w-[calc(100%-100px)]"
        } `}
      >
        <div className="flex justify-between mb-2 bg-white px-10 py-4 items-center rounded-b-lg gap-2 ">
          <div className="flex items-center gap-2">
            <button onClick={() => setToggle(!toggle)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                {toggle ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                )}
              </svg>
            </button>
            <h1 className="lg:text-xl font-medium text-lg text-blue-800">
              {TitleHandler()}
            </h1>
          </div>
          <div className=" hidden xs:flex items-center bg-gray-light px-[15px] py-[2px] rounded-[16px] justify-between">
            <button className="text-purple-800 hover:text-blue-800 transition-all duration-[0.3s]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
            <input
              type="text"
              placeholder="Search here..."
              className=" outline-none focus:outline-none py-[8px] px-2 text-gray-semiLight bg-transparent sm:w-full w-[100px] md:w-[240px] lg:w-[300px] xxl:w-[500px]"
            />
          </div>

          <div className="flex items-center gap-4">
            <div className="p-2 rounded bg-orange-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-orange-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                />
              </svg>
            </div>
            {user.role === "customer" ? (
              <Link to="/card" className="p-2 rounded bg-green-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 text-green-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>
              </Link>
            ) : (
              ""
            )}

            <img
              src={
                user.photo
                  ? `https://api.modirecontent.com/img/users/${user.photo}`
                  : `https://api.modirecontent.com/img/profile-placeholder.jpg`
              }
              alt=""
              className="w-[40px] rounded md:block hidden"
            />
            <div className=" flex-col text-sm hidden lg:flex">
              <div className="flex gap-2 items-center">
                <p className="font-medium text-[#151D48]">
                  {user.name ? user.name : user.username}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>

              <p className="text-gray-400">
                {user.role == "customer" ? "Employer" : user.role}
              </p>
            </div>
          </div>
        </div>

        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
